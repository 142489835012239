
.autoForm {
  margin-top: 12px;
  padding-left: 12px;
  padding-right: 24px;
  border-radius: 8px;
  background: #FFFFFF;

  :global {
    .adm-input-element {
      font-size: 14px;
      text-align: right;
    }
  }

  .form {
    .formItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      border-bottom: 1px solid #DDDDDD;
      &:last-child {
        border-bottom: none;
      }
      .label {
        width: 80px;
        font-size: 14px;
        color: #000000;
      }
      .form-content {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .radioBox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .radio {
      padding: 6px 32px;
      border-radius: 36px;
      background:#F5F5F5;
      border: 1px solid #F5F5F5;
      font-size: 13px;
      color: #262626;
      margin-left: 12px;
      &.active {
        background: #FFFFFF;
        border-color: var(--color-primary);
        color: var(--color-primary);
      }
    }
  }

}