@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.interceptModalBox {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.55);
  padding: 20px;
  .modalBox{
    position: relative;
    margin-top: 107px;
    height: 320px;
    width: 100%;
    background: linear-gradient(245.27deg, #FCD0D0 -2.04%, #FEF4F4 33.73%, #FCF5F2 103.88%);
    border-radius: 10px;
    padding: 16px;
    .interceptMoney{
      position: absolute;
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
      width: 150px;
      // height: 96px;
    }
    .interceptBox{
      margin-top: 60px;
      .creditLimit{
        text-align: center;
        color: var(--color-primary);
        font-size: 24px;
        font-weight: 600;
      }
      .creditIntro{
        text-align: center;
        margin-top: 8px;
        color: #8C8C8C;
        font-size: 14px;
      }
      .phoneBox {
        height: 46px;
        background: #FFFFFF;
        border: 1px solid var(--color-primary);
        border-radius: 24px;
        display: flex;
        align-items: center;
        margin: 16px auto 0;
        padding-left: 16px;
        .label {
          width: 170px;
          text-align: center;
          font-size: 16px;
        }
        .phoneNum{
          color: #BFBFBF;
          margin-right: 8px;
        }
        .line{
          width: 1px;
          height: 15px;
          margin-right: 8px;
          background-color: #D9D9D9;
        }
        :global {
          .adm-input-element {
            font-size: 16px;
            line-height: 30px;
            &::placeholder {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
      .getCms {
        margin-top: 16px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--linear-gradient);
        border-radius:23px;
        color: #FFFFFF;
        font-size: 16px;
      }
      .agreement {
        display: flex;
        margin-top: 12px;
        font-size: 12px;
        color: #8C8C8C;
        line-height: 18px;
  
        .agreementText{
          width: calc(100% - 22px);
          .primary {
            color: var(--color-primary);
          }
        }
    
        .checked {
          margin-top: 2px;
          margin-right: 6px;
          margin-bottom: 3px;
          border: 1px solid #D9D9D9;
          border-radius: 100%;
          width: 14px;
          height: 14px;
    
          &.active {
            position: relative;
            border: none;
            background: var(--color-primary);
    
            &::before {
              position: absolute;
              top: 3px;
              left: 2px;
              width: 10px;
              height: 7px;
              content: "";
              background-image: url("~@imgs/activity/check.png");
              background-size: 100%;
            }
          }
        }
      }
    }
    .cancleBtn{
      position: absolute;
      bottom:-54px;
      left: 50%;
      transform: translateX(-50%);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #BFBFBF;
      @include flex-cc;
      color: #7f7f7f;
    }
  }
}

.dialogOverlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  &.showPopup {
    display: block;
  }
  .dialogMain {
    width: 320px;
    height: 180px;
    border-radius: 8px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 0;
    box-sizing: border-box;
    .close {
      width: 100%;
      font-size: 20px;
      text-align: right;
      margin-right: 20px;
      color: rgb(214, 217, 223);
      padding-right: 20px;
      box-sizing: border-box;
    }
    .inputBox {
      width: 285px;
      height: 48px;
      border: 1px solid #e19459;
      margin: 10px auto;
      padding-left: 20px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      .code {
        width: 150px;
        text-align: center;
        color: #e19459;
      }
      :global {
        .adm-input-element {
          font-size: 15px;
          line-height: 30px;
        }
      }
    }
    .getQuota {
      margin: 18px auto;
      width: 283px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
      border-radius: 6px;
      color: #FFFFFF;
      font-size: 16px;
    }
  }
}
