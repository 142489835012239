@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin19 {
  font-family: "PingFang SC";
  padding: 40px 20px 0 20px;
  .borrowBox{
    display: flex;
    align-items: end;
  }
  :global {
    .adm-input-element {
      font-family: DIN Alternate;
      font-weight: 600;
      font-size: 40px;
      line-height: 100%;
      letter-spacing: 0%;
      flex: 1;
    }
    .adm-card{
      border-radius: 0;
      padding: 0 16px;
    }
    .adm-card-body{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      padding: 8px 0;
    }
    .adm-button{
      width: 130px;
    }
  }
  .divider{
    margin: 0 auto;
    width: 320px;
    height: 2px;
    background-color: #D9D9D9;
  }
  .borrowBottomText{
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #8C8C8C;
    margin: 0 auto;
    padding: 10px;
    .number{
      color: #FF2121
    }
  }
  .borrowCondition{
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    .cardLeft{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 14px;
    }
    .cardRight{
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 11px;
      text-align: right;
    }
  }
  .tipBox {
    margin-top: 12px;
    padding-bottom: 50px;
    .tip {
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }
  .getCms {
    height: 46px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    border-radius:23px;
    color: #FFFFFF;
    font-size: 16px;
    width: 100%;
  }
  .agreement {
    margin: 16px 0 12px 0;
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    margin-top: 12px;
    font-size: 12px;
    color: #8C8C8C;
    line-height: 18px;

    .primary {
      color: var(--color-primary);
    }
  }
  .phoneBox{
    width: 100%;
    height: 100%;
    :global {
      .adm-input-element {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        margin-right: 16px;
      }
      .phone-input_phoneBox{
        width: 100%;
      }
    }
    .defaultPhone{
      padding-top: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 24px;
    }
    .checked {
      margin: 1px 6px 3px 0;
      border: 1px solid #D9D9D9;
      border-radius: 100%;
      width: 14px;
      height: 14px;

      &.active {
        position: relative;
        border: none;
        background: var(--color-primary);

        &::before {
          position: absolute;
          top: 3px;
          left: 2px;
          width: 10px;
          height: 7px;
          content: "";
          background-image: url("~@imgs/activity/check.png");
          background-size: 100%;
        }
      }
    }
    .otherLogin{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 16px;
      margin-top: 14px;
    }
  }
  .dialogOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    &.showPopup {
      display: block;
    }
    .dialogMain {
      width: 320px;
      height: 180px;
      border-radius: 8px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 0;
      box-sizing: border-box;
      .close {
        width: 100%;
        font-size: 20px;
        text-align: right;
        margin-right: 20px;
        color: rgb(214, 217, 223);
        padding-right: 20px;
        box-sizing: border-box;
      }
      .inputBox {
        width: 285px;
        height: 48px;
        border: 1px solid #e19459;
        margin: 10px auto;
        padding-left: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .code {
          width: 150px;
          text-align: center;
          color: #e19459;
        }
        :global {
          .adm-input-element {
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      .getQuota {
        margin: 18px auto;
        width: 283px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
  .popup{
    .popopButton{
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
  }

}
