.phoneBox {
  height: 46px;
  background: #F5F5F5;
  border-radius: 24px;
  display: flex;
  align-items: center;
  margin: 9px auto 0;
  padding-left: 16px;
  flex-direction: row;
  .label {
    width: 170px;
    text-align: center;
    font-size: 16px;
  }
  :global {
    .adm-input-element {
      font-size: 16px;
      line-height: 30px;
      &::placeholder {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}
.phoneNotNeedInput{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 9px auto 0;
  height: 46px;
  .encryptPhone{
    color: #262626;
    font-size: 16px;
  }
  .editIcon{
    margin-left: 12px;
    color:#8C8C8C ;
  }
}